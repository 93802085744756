'use client';

import React, { ReactNode, useContext } from 'react';
import { ThemeContext } from '@/components/Theme/theme-provider';

const Body = ({ className, children }: { className: string; children: ReactNode }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <body className={className} data-theme={theme}>
      {children}
    </body>
  );
};

export default Body;
