'use client';

import React, { createContext } from 'react';

export const ThemeContext = createContext<{
  theme: string;
  setTheme: any;
}>({
  theme: 'light',
  setTheme: null,
});
export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = React.useState('light');
  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
}
